import {
  machineActionType,
  setAllMachines,
  deleteMachine,
  setSelectedMachine,
  updateMachineProps,
  machineType,
} from "./types";
import { getNewMachine } from "./../../development/initializer";
import rest from "../../rest";
import { appState } from "..";
import { reduxSetNotification } from "../user/actions";
import { TFunction } from "i18next";

// action creators
export function reduxSetAllMachines(
  allMachines: machineType[]
): setAllMachines {
  return {
    type: machineActionType.setAllMachines,
    payload: allMachines,
  };
}

export function reduxSetSelectedMachine(machineID: string): setSelectedMachine {
  return {
    type: machineActionType.setSelectedMachine,
    payload: machineID,
  };
}

export function reduxDeleteMachine(machineID: string): deleteMachine {
  return {
    type: machineActionType.deleteMachine,
    payload: machineID,
  };
}

export function reduxUpdateMachineProperty<T extends keyof machineType>(
  machineProperty: Pick<machineType, T>
) {
  return {
    type: machineActionType.updateMachineProperty,
    payload: machineProperty,
  };
}

export function reduxUpdateMachineProps(
  machine: machineType
): updateMachineProps {
  return {
    type: machineActionType.updateMachineProps,
    payload: machine,
  };
}

export function setMachineHasChanged(machineHasChanged: boolean) {
  return {
    type: machineActionType.setMachineHasChanged,
    payload: machineHasChanged,
  };
}

export function addNewReduxMachine(machine: machineType) {
  return {
    type: machineActionType.addNewReduxMachine,
    payload: machine,
  };
}

export const removeMachine = (
  t: TFunction<"translation", undefined, "translation">,
  machineID: string
) => {
  return (dispatch: any) => {
    rest
      .delete("/machines/" + machineID, { withCredentials: true })
      .then((response) => {
        dispatch(reduxDeleteMachine(machineID));
        dispatch(reduxSetSelectedMachine(""));
      })
      .catch(() => {
        console.error("Failed to delete machine with machineID ", machineID);
        dispatch(
          reduxSetNotification({
            style: "error",
            message: t("store.machine.deleteMachineError"),
            open: true,
          })
        );
      });
  };
};

export function addNewMachine() {
  return (dispatch: any, getState: () => appState) => {
    const { user } = getState();
    const userID = user.currentUser ? user.currentUser.id : "";
    const newMachine = getNewMachine(userID);
    dispatch(addNewReduxMachine(newMachine));
  };
}

export const saveMachineToServer = (
  t: TFunction<"translation", undefined, "translation">,
  name: string,
  description: string,
  companyID: string,
  machineID?: string,
  sentToServer?: boolean,
  nameTag?: string
) => {
  return (dispatch: any, getState: () => appState) => {
    if (!sentToServer) {
      rest
        .post(
          "/machines",
          {
            name: name,
            description: description,
            companyID: companyID,
            nameTag: nameTag,
          },
          { withCredentials: true }
        )
        .then((response) => {
          const machineID = response.data.id;

          if (machineID.length !== 0) {
            rest
              .get("/machines", { withCredentials: true })
              .then((response) => {
                const newMachine: machineType = response.data.list.find(
                  (machine: machineType) => machine.name === name
                );

                rest
                  .get("/machines/" + newMachine.id, {
                    withCredentials: true,
                  })
                  .then((machineResponse) => {
                    dispatch(
                      reduxSetAllMachines(
                        getState()
                          .machine.allMachines.filter(
                            (machine) => machine.name !== ""
                          )
                          .concat({
                            ...response.data.list.find(
                              (machine: machineType) => machine.name === name
                            ),
                            hashKey: machineResponse.data.hashKey,
                          })
                      )
                    );
                  })
                  .catch((reason) => console.log("Can't get machines", reason));
              })
              .catch((error) => {
                console.log(error);
                dispatch(setMachineHasChanged(true));
              });
          }
          dispatch(reduxSetSelectedMachine(machineID));
          dispatch(setMachineHasChanged(false));
          dispatch(
            reduxSetNotification({
              style: "success",
              message: t("store.machine.machineAdded"),
              open: true,
            })
          );
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else if (error.message === "Request failed with status code 500") {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("store.machine.machineExists"),
                open: true,
              })
            );
          } else {
            alert(t("statusProgress.unexpectedError"));
          }

          dispatch(setMachineHasChanged(true));
        });
    } else if (sentToServer) {
      rest
        .patch(
          "/machines/" + machineID,
          {
            name: name,
            description: description,
            companyID: companyID,
            nameTag: nameTag,
          },
          { withCredentials: true }
        )
        .then((response) => {
          dispatch(
            reduxUpdateMachineProperty({
              name: name,
              description: description,
            })
          );
          dispatch(setMachineHasChanged(false));
          dispatch(
            reduxSetNotification({
              style: "success",
              message: t("store.machine.machineUpdated"),
              open: true,
            })
          );
        })
        .catch((error) => {
          dispatch(setMachineHasChanged(true));
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("store.machine.machineUpdateError"),
                open: true,
              })
            );
          }
          console.log("error", error);
        });
    }
  };
};
