import React, { useEffect, useRef, useState } from "react";
import "./metaData.css";
import { Reveal } from "@progress/kendo-react-animation";
import {
  reduxSetInformationRequest,
  reduxSetNotification,
} from "../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { translateToSelectedLanguage } from "../../store/plot/types";
import { reduxSetPlotMetaData } from "../../store/plot/actions";
import { appState } from "../../store";
import {
  FileProps,
  measurementMetaData,
  measurementProps,
} from "../../store/scheduler/types";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import { cPhoneWidthLim } from "../../app";
import {
  setEmptySignal,
  translateFileProps,
} from "../../helpers/genericHelpers";
import * as measurementsAPI from "./../../api/netRail/measurements";
import * as plotAPI from "./../../api/netRail/plot";
import * as filesAPI from "./../../api/netRail/files";
import * as machinesAPI from "./../../api/netRail/machines";
import Scrollbars from "react-custom-scrollbars";
import { machineType } from "../../store/machine/types";
import { helpInformationPath, roleType } from "../../store/user/types";
import { Spinner } from "../spinner";
import { updateMeasurementProperty } from "../../store/scheduler/actions";
import axios from "axios";
import { customThumb } from "../manageUsers/userList";
import { useTranslation } from "react-i18next";

export const MetaData: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [expandCard, setExpandCard] = useState(true);
  const [metadata, setmetadata] = useState<measurementMetaData>();
  const [currentMeasurements, setCurrentMeasurements] =
    useState<measurementProps>();
  const [localDateStart, setLocalDateStart] = useState<Date>();
  const [localDateEnd, setLocalDateEnd] = useState<Date>();
  const [SNR, setSNR] = useState<number>();
  const [currentMachine, setCurrentMachine] = useState<machineType>();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const FileProps = "generic";

  const plans = useSelector((state: appState) => state.scheduler.plans);
  const machines = useSelector((state: appState) => state.machine.allMachines);
  const selectedPlan = useSelector(
    (state: appState) => state.scheduler.selectedPlan
  );

  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);
  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );
  const measurements = useSelector((state: appState) => state.scheduler.plans)
    .map((plan) => plan.measurements)
    .flat();

  const informationRequest = useSelector(
    (state: appState) => state.user.informationRequest
  );

  const currentUser = useSelector((state: appState) => state.user.currentUser);

  const plan = plans.find((plan) => plan.id === selectedPlan);

  let measurement: measurementProps | undefined = undefined;

  if (measurements.some((meas) => meas != undefined)) {
    measurement = measurements.find((meas) => meas.id === selectedMeasurement);
  }

  const [measurementFiles, setMeasurementFiles] = useState<
    FileProps[] | undefined
  >(measurement?.files);

  const asyncGetMetaData = async () => {
    // Initialize metadata
    const metaData = {
      trackGauge: setEmptySignal(),
      crossLevel: setEmptySignal(),
      crossLevelBIS: setEmptySignal(),
      crossLevelUnevenness: setEmptySignal(),
      twist6m: setEmptySignal(),
      twist3m: setEmptySignal(),
      alignment: setEmptySignal(),
      longitudinalLevel: setEmptySignal(),
      alignmentLeft: setEmptySignal(),
      longitudinalLevelLeft: setEmptySignal(),
      alignmentRight: setEmptySignal(),
      longitudinalLevelRight: setEmptySignal(),
    } as measurementMetaData;

    // Populate all metadata. Request metadata in parallel, if it fails, do each sequentially.
    // This is done since there is no nice way for await Promise.all(metadataPromises); to handle indivudal errors.
    // It's all or nothing.
    const metadataPromises = [];

    try {
      for (const key in metaData) {
        if (key === "crossLevelBIS") continue;
        metadataPromises.push(getMeasurementMetaData(key, selectedMeasurement));
      }

      const response = await Promise.all(metadataPromises);

      let idx = 0;
      for (const key in metaData) {
        if (key === "crossLevelBIS") continue;
        metaData[key as keyof measurementMetaData] = response[idx];
        idx += 1;
      }
    } catch (err) {
      for (const key in metaData) {
        let vals;
        try {
          vals = await getMeasurementMetaData(key, selectedMeasurement);
          metaData[key as keyof measurementMetaData] = vals;
        } catch (err) {
          metaData[key as keyof measurementMetaData] = setEmptySignal();
        }
      }
    }

    //set metadata
    dispatch(
      reduxSetPlotMetaData({
        ...metaData,
        crossLevelBIS: {
          endKm: metaData["crossLevel"].endKm,
          endMeter: metaData["crossLevel"].endMeter,
          endTick: metaData["crossLevel"].endTick,
          startKm: metaData["crossLevel"].startKm,
          startMeter: metaData["crossLevel"].startMeter,
          startTick: metaData["crossLevel"].startTick,
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
      })
    );

    setmetadata({
      ...metaData,
      crossLevelBIS: {
        endKm: metaData["crossLevel"].endKm,
        endMeter: metaData["crossLevel"].endMeter,
        endTick: metaData["crossLevel"].endTick,
        startKm: metaData["crossLevel"].startKm,
        startMeter: metaData["crossLevel"].startMeter,
        startTick: metaData["crossLevel"].startTick,
        totalKrit: 0,
        totalPlan: 0,
        totalStop: 0,
        totalUh1: 0,
        totalUh2: 0,
      },
    });
    const from =
      (metaData["trackGauge"].startKm
        ? metaData["trackGauge"].startKm * 1000
        : 0) +
      (metaData["trackGauge"].startMeter
        ? metaData["trackGauge"].startMeter
        : 0);
    const to =
      (metaData["trackGauge"].endKm ? metaData["trackGauge"].endKm * 1000 : 0) +
      (metaData["trackGauge"].endMeter ? metaData["trackGauge"].endMeter : 0);

    let snr;

    // Legacy protection, added SNR to metadata 3/7-2023. If it's not available get snr from trackgauge quality idx.
    if (metaData["trackGauge"].snr !== null) {
      snr = metaData["trackGauge"].snr;
    } else {
      const qualityData = await plotAPI.getPlotData(
        selectedMeasurement,
        from,
        to,
        2,
        "trackGauge",
        ["quality"]
      );

      snr =
        Math.round(
          (qualityData
            ? qualityData?.filter((point) => point.quality === false).length /
              qualityData?.length
            : 0) *
            100 *
            100
        ) / 100;
    }

    setSNR(snr);
  };

  const fetchMeasurementData = async () => {
    try {
      const measurement = await measurementsAPI.getMeasurement(
        selectedMeasurement
      );

      setCurrentMeasurements(measurement);
      setLocalDateStart(new Date(measurement?.createdAt as string));
      setLocalDateEnd(new Date(measurement?.doneAt as string));

      const serverMachine = await machinesAPI.getMachine(measurement.machineID);

      setCurrentMachine(serverMachine);
    } catch {
      console.log("Unable to set current measurement");
    }
  };

  useEffect(() => {
    if (selectedMeasurement !== "-1") {
      // Needed to wrap the data in this check, otherwise it runs before the selected measurement has been set in the DashboardView component.
      asyncGetMetaData();

      fetchMeasurementData();

      // const currentMachineLocal = machines.find((machine) => {
      //   if (machine.id === currentMeasurements?.machineID) return machine;
      // });

      // setCurrentMachine(currentMachineLocal);
    }
  }, [selectedMeasurement]);

  useEffect(() => {
    setMeasurementFiles(measurement?.files);
  }, [measurement?.files]);

  let sumStop = 0;
  let sumKrit = 0;
  let sumUH2 = 0;
  let sumUH1 = 0;
  let sumPlan = 0;
  Object.entries(metadata ? metadata : []).map((meas: any) => {
    if (meas[0] !== "longitudinalLevel" && meas[0] !== "alignment") {
      sumStop += meas[1].totalStop;
      sumKrit += meas[1].totalKrit;
      sumUH2 += meas[1].totalUh2;
      sumUH1 += meas[1].totalUh1;
      sumPlan += meas[1].totalPlan;
    }
  });

  const distanceData = (position: string) => {
    let metadataDistance;

    switch (position) {
      case "startKm":
        if (currentMeasurements && currentMeasurements?.startAtKm !== null) {
          metadataDistance = currentMeasurements.startAtKm;
        } else {
          if (metadata !== undefined)
            if (metadata.trackGauge.startKm < 10) {
              return metadata.trackGauge.startKm.toPrecision(1);
            } else if (
              metadata.trackGauge.startKm < 100 &&
              metadata.trackGauge.startKm >= 10
            ) {
              return metadata.trackGauge.startKm.toPrecision(2);
            } else {
              return metadata.trackGauge.startKm.toPrecision(3);
            }
          else {
            return "-";
          }
        }
        break;

      case "startMeter":
        if (currentMeasurements && currentMeasurements?.startAtMeter !== null) {
          metadataDistance = currentMeasurements.startAtMeter;
        } else {
          if (metadata !== undefined)
            if (metadata.trackGauge.startMeter < 10) {
              return metadata.trackGauge.startMeter.toPrecision(1);
            } else if (
              metadata.trackGauge.startMeter < 100 &&
              metadata.trackGauge.startMeter >= 10
            ) {
              return metadata.trackGauge.startMeter.toPrecision(2);
            } else {
              return metadata.trackGauge.startMeter.toPrecision(3);
            }
          else {
            return "-";
          }
        }
        break;
      case "endKm":
        if (currentMeasurements && currentMeasurements?.endAtKm !== null) {
          metadataDistance = currentMeasurements.endAtKm;
        } else {
          if (metadata !== undefined)
            if (metadata.trackGauge.endKm < 10) {
              return metadata.trackGauge.endKm.toPrecision(1);
            } else if (
              metadata.trackGauge.endKm < 100 &&
              metadata.trackGauge.endKm >= 10
            ) {
              return metadata.trackGauge.endKm.toPrecision(2);
            } else {
              return metadata.trackGauge.endKm.toPrecision(3);
            }
          else {
            return "-";
          }
        }
        break;
      case "endMeter":
        if (currentMeasurements && currentMeasurements.endAtMeter !== null) {
          metadataDistance = currentMeasurements.endAtMeter;
        } else {
          if (metadata !== undefined)
            if (metadata.trackGauge.endMeter < 10) {
              return metadata.trackGauge.endMeter.toPrecision(1);
            } else if (
              metadata.trackGauge.endMeter < 100 &&
              metadata.trackGauge.endMeter >= 10
            ) {
              return metadata.trackGauge.endMeter.toPrecision(2);
            } else {
              return metadata.trackGauge.endMeter.toPrecision(3);
            }
          else {
            return "-";
          }
        }
        break;
    }
    return metadataDistance;
  };

  const getMeasurementTime = () => {
    let startHour;
    let endHour;
    let startMin;
    let endMin;

    if (localDateStart && localDateEnd) {
      localDateStart.getMinutes() < 10
        ? (startMin = "0" + localDateStart.getMinutes())
        : (startMin = localDateStart.getMinutes());
      localDateStart.getHours() < 10
        ? (startHour = "0" + localDateStart.getHours())
        : (startHour = localDateStart.getHours());

      localDateEnd.getMinutes() < 10
        ? (endMin = "0" + localDateEnd.getMinutes())
        : (endMin = localDateEnd.getMinutes());
      localDateEnd.getHours() < 10
        ? (endHour = "0" + localDateEnd.getHours())
        : (endHour = localDateEnd.getHours());

      return startHour + ":" + startMin + " - " + endHour + ":" + endMin;
    } else {
      return "-:- - -:-";
    }
  };

  const objectData = useSelector((state: appState) => state.plot.allObjects);

  const objectsSet = new Set(objectData?.map((point) => point.type));

  const uniquesObjects = Array.from(new Set(Array.from(objectsSet).flat()));

  let objectsString = translateToSelectedLanguage(t, uniquesObjects[0]);
  for (let i = 1; i < uniquesObjects.length; i++) {
    objectsString += ", " + translateToSelectedLanguage(t, uniquesObjects[i]);
  }

  const downloadFile = async (file: FileProps) => {
    setDownloading(true);
    await filesAPI.downloadFile(file);
    setDownloading(false);
  };

  const deleteFile = (file: FileProps) => {
    setLoading(true);
    try {
      setTimeout(async () => {
        await filesAPI.deleteFile(file.id);

        const cleanedFiles = measurementFiles?.filter(
          (measurementFile) => measurementFile.id !== file.id
        );

        dispatch(
          updateMeasurementProperty({
            files: cleanedFiles ? cleanedFiles : [],
          })
        );

        dispatch(
          reduxSetNotification({
            message: t("common.fileDeleted"),
            style: "success",
            open: true,
          })
        );

        setLoading(false);
      }, 2000);
    } catch (error) {
      dispatch(
        reduxSetNotification({
          message: t("metaData.unableToDeletFile"),
          style: "error",
          open: true,
        })
      );

      setLoading(false);
    }
  };

  const inputRef = useRef<any>(null);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const localFile = event.target.files[0];
    if (!localFile) {
      dispatch(
        reduxSetNotification({
          message: t("common.noFileHasBeenSelected"),
          style: "error",
          open: true,
        })
      );

      return;
    }

    const reader = new FileReader();

    if (measurement) {
      try {
        const { presignedURL, fileKey } = await filesAPI.getUploadURL(
          localFile.name,
          FileProps,
          localFile.size,
          "measurement",
          measurement.id,
          "complete",
          0
        );
        if (fileObj && currentUser && presignedURL) {
          reader.readAsArrayBuffer(fileObj);

          reader.onload = async (e) => {
            if (e.target) {
              const instance = axios.create({
                baseURL: presignedURL,
              });

              instance.interceptors.request.use(async function (config) {
                config.headers["X-Amz-meta-Name"] = fileObj.name;
                config.headers["X-Amz-meta-Owner_type"] = "measurement";
                config.headers["X-Amz-meta-Owner_id"] = measurement?.id;
                config.headers["X-Amz-meta-Part_type"] = "complete";
                config.headers["X-Amz-meta-Part"] = "0";
                config.headers["X-Amz-meta-Type"] = FileProps;
                config.headers["X-Amz-meta-Size"] = fileObj.size.toString();
                config.headers["X-Amz-meta-Path"] = fileKey;

                return config;
              });

              const binaryString = reader.result;
              setLoading(true);
              instance
                .put(presignedURL, binaryString)
                .then((res) => {
                  setTimeout(async () => {
                    try {
                      let serverMeasurement: measurementProps | undefined =
                        undefined;
                      if (measurement) {
                        serverMeasurement =
                          await measurementsAPI.getMeasurement(measurement.id);

                        dispatch(
                          updateMeasurementProperty({
                            files: serverMeasurement.files,
                          })
                        );

                        dispatch(
                          reduxSetNotification({
                            message: t("common.uploadedFile"),
                            style: "success",
                            open: true,
                          })
                        );
                      }

                      setLoading(false);
                    } catch (error) {
                      setLoading(false);
                    }
                  }, 2000);
                })
                .catch((err: any) => {
                  dispatch(
                    reduxSetNotification({
                      message: t("common.uploadFailed"),
                      style: "error",
                      open: true,
                    })
                  );

                  console.error({ err });
                  setLoading(false);
                });
            }
          };
        }
      } catch (error) {
        dispatch(
          reduxSetNotification({
            message: t("metaData.uploadFailedControlFileFormat"),
            style: "error",
            open: true,
          })
        );
      }
    }
  };

  const getFileDateAdded = (dateAdded: string) => {
    const date = new Date(dateAdded as string);

    if (date instanceof Date && !isNaN(date.getTime())) {
      const day =
        date.getDate() < 10
          ? "0" + date.getDate().toString()
          : date.getDate().toString();
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : (date.getMonth() + 1).toString();
      const year = date.getFullYear().toString();

      const hour = date.getHours().toString();
      const minute = date.getMinutes().toString();

      return year + "-" + month + "-" + day + " " + hour + ":" + minute;
    } else {
      return "yyyy-mm-dd hh:mm";
    }
  };

  const measurementFilesListGrid = (files?: FileProps[]) => {
    return (
      <div className="MeasurmentFilesListGrid">
        <Scrollbars
          thumbSize={60}
          renderThumbVertical={customThumb}
          autoHide={true}
          autoHideTimeout={1000}
          autoHideDuration={500}
        >
          <div className="GridFileContainer">
            <div className="HeaderFile1" style={{ color: "black" }}>
              {t("metaData.manage")}
            </div>
            <div className="HeaderFile2" style={{ color: "black" }}>
              {t("metaData.fileType")}
            </div>
            <div className="HeaderFile3" style={{ color: "black" }}>
              {t("metaData.name")}
            </div>
            <div className="HeaderFile4" style={{ color: "black" }}>
              {t("metaData.added")}
            </div>
          </div>

          {files?.map((file, fileIdx) => {
            if (currentUser?.roles.some((role) => role.name === roleType.admin))
              return (
                <div className="GridFileContainer" key={fileIdx}>
                  <div className="File1">
                    <span
                      onClick={() => {
                        downloadFile(file);
                      }}
                      className="k-icon k-i-download HandleFileButton"
                      style={{
                        opacity: loading ? "0.2" : "1",
                        fontSize: "24px",
                      }}
                    />

                    {file.type === "generic" &&
                    currentUser.roles.some(
                      (role) => role.type !== roleType.external
                    ) ? (
                      <span
                        onClick={() => {
                          if (!loading) deleteFile(file);
                        }}
                        className="k-icon k-i-delete HandleFileButton"
                        style={{
                          opacity: loading ? "0.2" : "1",
                          fontSize: "24px",
                        }}
                      />
                    ) : (
                      <span // empty placeholder
                        className="k-icon k-i-delete"
                        style={{
                          color: "transparent",
                          fontSize: "24px",
                        }}
                      />
                    )}
                  </div>

                  <div className="File2">
                    {translateFileProps(file.type, t)}
                  </div>
                  <div className="File3">{file.name}</div>
                  <div className="File4">
                    {getFileDateAdded(file.createdAt)}
                  </div>
                </div>
              );
            else if (
              file.type === "generic" ||
              file.type === "excel"
              // ||
              // file.type === "csv"  // TODO: Need to fix this ones CSV files are validated with trafikverket.
            )
              return (
                <div className="GridFileContainer">
                  <div className="File1">
                    <span
                      onClick={() => {
                        downloadFile(file);
                      }}
                      className="k-icon k-i-download HandleFileButton"
                      style={{
                        fontSize: "24px",
                        opacity: loading ? "0.2" : "1",
                      }}
                    />
                    {file.type === "generic" &&
                    currentUser?.roles.some(
                      (role) => role.type !== roleType.external
                    ) ? (
                      <span
                        onClick={() => {
                          if (!loading) deleteFile(file);
                        }}
                        className="k-icon k-i-delete HandleFileButton"
                        style={{
                          fontSize: "24px",
                          opacity: loading ? "0.2" : "1",
                        }}
                      />
                    ) : (
                      <span // empty placeholder
                        className="k-icon k-i-delete"
                        style={{
                          color: "transparent",
                          fontSize: "24px",
                        }}
                      />
                    )}
                  </div>

                  <div className="File2">
                    {translateFileProps(file.type, t)}
                  </div>
                  <div className="File3">{file.name}</div>
                  <div className="File4">
                    {getFileDateAdded(file.updatedAt)}
                  </div>
                </div>
              );
          })}
        </Scrollbars>
      </div>
    );
  };

  const children = expandCard ? (
    <div className="HeaderContainer">
      <div className="ItemRow">
        <div className="ItemCard">
          <div className="ItemCardStyle">
            {sumStop}
            <div
              style={{
                color: "gray",
                fontSize: "medium",
                marginLeft: "3%",
              }}
            >
              {t("metaData.numberOfStop")}
            </div>
          </div>
          <div className="ItemCardStyle">
            {sumKrit}
            <div
              style={{
                color: "gray",
                fontSize: "medium",
                marginLeft: "3%",
              }}
            >
              {t("metaData.numberOfKrit")}
            </div>
          </div>
          <div className="ItemCardStyle">
            {sumUH2}
            <div
              style={{
                color: "gray",
                fontSize: "medium",
                marginLeft: "3%",
              }}
            >
              {t("metaData.numberOfUH2")}
            </div>
          </div>
          <div className="ItemCardStyle">
            {sumUH1}
            <div
              style={{
                color: "gray",
                fontSize: "medium",
                marginLeft: "3%",
              }}
            >
              {t("metaData.numberOfUH1")}
            </div>
          </div>
          <div className="ItemCardStyle">
            {sumPlan}
            <div
              style={{
                color: "gray",
                fontSize: "medium",
                marginLeft: "3%",
              }}
            >
              {t("metaData.numberOfPlan")}
            </div>
          </div>
        </div>

        <div className="ItemCard">
          <div className="ItemCardStyle" style={{ fontSize: "x-large" }}>
            {SNR}
            <div
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {
                      fontSize: "10px",
                      color: "gray",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                    }
                  : {
                      fontSize: "medium",
                      color: "gray",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                    }
              }
            >
              {t("metaData.percentageUnreliablePoints")}
            </div>
          </div>
          <div
            className="ItemCardStylePath"
            style={{
              display: "flex",
              fontSize: "x-large",
            }}
          >
            {measurement?.speedAvg
              ? Math.round(measurement?.speedAvg * 100) / 100
              : "-"}
            <div
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {
                      fontSize: "10px",
                      color: "gray",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                    }
                  : {
                      fontSize: "medium",
                      color: "gray",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                    }
              }
            >
              ±
            </div>
            {measurement?.speedStd !== null &&
            measurement?.speedStd !== undefined
              ? Math.round(measurement?.speedStd * 100) / 100
              : "-"}
            <div
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {
                      fontSize: "10px",
                      color: "gray",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                    }
                  : {
                      fontSize: "medium",
                      color: "gray",
                      paddingLeft: "3px",
                      paddingRight: "3px",
                    }
              }
            >
              km/h
            </div>{" "}
          </div>
          <div
            className="ItemCardStylePath"
            style={{
              display: "flex",
              fontSize: "x-large",
            }}
          >
            {measurement?.tempAvg ? measurement?.tempAvg : "-"}
            <div
              style={{
                color: "gray",
                fontSize: "medium",
                marginLeft: "5px",
              }}
            >
              &#176;C
              <span
                className="k-icon k-i-info informationButton"
                style={{ marginLeft: "10px", marginBottom: "3px" }}
                onClick={() => {
                  dispatch(
                    reduxSetInformationRequest({
                      path: helpInformationPath.measurementTemperature,
                      showInformation:
                        informationRequest?.path ===
                        helpInformationPath.measurementTemperature
                          ? !informationRequest?.showInformation
                          : true,
                    })
                  );
                }}
              />
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="ItemRow">
        <div className="ItemCardNotesAndObjects">
          <div
            className="ItemCardStylePath"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {t("metaData.notes")}
            <div className="TextNotesAndObjects">
              <Scrollbars
                thumbSize={30}
                renderThumbVertical={customThumb}
                autoHide={true}
                autoHideTimeout={1000}
                autoHideDuration={500}
              >
                {measurement?.notes
                  ? measurement.notes
                      .split("\n")
                      .map((str, idx) => <p key={idx + str}>{str}</p>)
                  : measurement?.notes}
              </Scrollbars>
            </div>
          </div>
        </div>
        <div className="ItemCardNotesAndObjects">
          <div
            className="ItemCardStylePath"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {t("metaData.railObject")}
            <div
              style={{
                fontSize: "small",
                color: "gray",
                paddingLeft: "3px",
              }}
            >
              {objectsString}
            </div>
          </div>
        </div>{" "}
      </div>{" "}
      <div className="ItemRow">
        <div className="ItemCardFile">
          <div className="ItemCardStyleFile">
            {t("metaData.files")}
            <div className="FileRow">
              <div
                className="FileWindow"
                style={{
                  width: currentUser === undefined ? "100%" : "90%",
                  marginRight: currentUser === undefined ? "6px" : "0px",
                }}
              >
                {downloading ? (
                  <Spinner />
                ) : (
                  measurementFilesListGrid(measurementFiles)
                )}
              </div>
              {currentUser === undefined ? null : (
                <div className="uploadButtonContainer">
                  <input
                    className="SelectFileButton"
                    type="file"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <span
                    className={
                      loading
                        ? "k-icon k-i-upload UploadFilesLoading"
                        : "k-icon k-i-upload UploadFiles"
                    }
                    onClick={() => {
                      if (!loading) handleClick();
                    }}
                    style={{
                      fontSize: "24px",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  const animation = (
    <Reveal
      style={{
        zIndex: 1,
      }}
    >
      {children}
    </Reveal>
  );

  return localDateStart && localDateEnd ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "var(--background-color)",
        paddingTop: "50px",
      }}
    >
      <div className="HeaderContainer2">
        <div className="ItemRow">
          <div
            className="ItemCard"
            style={{
              flexDirection: "row",
              alignContent: "space-evenly",
            }}
          >
            <div
              className="ItemCardStyleDate"
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {
                      fontSize: "large",
                      color: "black",
                    }
                  : {
                      fontSize: "x-large",
                      color: "black",
                    }
              }
            >
              {localDateStart.getFullYear().toString() + "-"}
              {localDateStart.getMonth() + 1 < 10
                ? "0" + (localDateStart.getMonth() + 1).toString() + "-"
                : (localDateStart.getMonth() + 1).toString() + "-"}
              {localDateStart.getDate() < 10
                ? "0" + localDateStart.getDate().toString() + "-"
                : localDateStart.getDate().toString()}

              <div
                style={
                  width <= cPhoneWidthLim || height <= cPhoneWidthLim
                    ? {
                        fontSize: "10px",
                        color: "gray",
                        paddingLeft: "3px",
                      }
                    : {
                        fontSize: "medium",
                        color: "gray",
                        paddingLeft: "3px",
                      }
                }
              >
                {t("common.date")}
              </div>
            </div>
            <div
              className="ItemCardStyleTime"
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {
                      fontSize: "large",
                      color: "black",
                    }
                  : {
                      fontSize: "x-large",
                      color: "black",
                    }
              }
            >
              {getMeasurementTime()}
              <div
                style={
                  width <= cPhoneWidthLim || height <= cPhoneWidthLim
                    ? {
                        fontSize: "10px",
                        color: "gray",
                        paddingLeft: "3px",
                      }
                    : {
                        fontSize: "medium",
                        color: "gray",
                        paddingLeft: "3px",
                      }
                }
              >
                {t("metaData.timeOfMeasurement")}
              </div>
            </div>
            <div
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? {
                      fontSize: "large",
                      color: "black",
                      lineBreak: "anywhere",
                    }
                  : {
                      fontSize: "x-large",
                      color: "black",
                      lineBreak: "anywhere",
                    }
              }
            >
              {currentMeasurements &&
              currentMeasurements.machineNameTag &&
              currentMeasurements.machineNameTag !== ""
                ? currentMeasurements.machineNameTag
                : currentMachine && currentMachine.name !== ""
                ? currentMachine.name
                : "-"}
              <span
                style={
                  width <= cPhoneWidthLim || height <= cPhoneWidthLim
                    ? {
                        fontSize: "10px",
                        color: "gray",
                        paddingLeft: "3px",
                      }
                    : {
                        fontSize: "medium",
                        color: "gray",
                        paddingLeft: "3px",
                      }
                }
              >
                {t("common.machine")}
              </span>
            </div>
          </div>
          <div className="ItemCard">
            <div
              className="ItemCardStyleTrack"
              style={
                width <= cPhoneWidthLim || height <= cPhoneWidthLim
                  ? { fontSize: "18px" }
                  : { fontSize: "x-large" }
              }
            >
              <div className="ItemCardStylePath">
                {measurement?.trackNumber ? measurement?.trackNumber : "TN-NaN"}
                <div
                  style={
                    width <= cPhoneWidthLim || height <= cPhoneWidthLim
                      ? {
                          fontSize: "12px",
                          color: "gray",
                          paddingLeft: "5px",
                        }
                      : {
                          fontSize: "medium",
                          color: "gray",
                          paddingLeft: "5px",
                        }
                  }
                >
                  {t("common.trackNumber")}
                </div>
              </div>
              <div className="ItemCardStylePath">
                {plan?.trackPart}
                <div
                  style={
                    width <= cPhoneWidthLim || height <= cPhoneWidthLim
                      ? {
                          fontSize: "12px",
                          color: "gray",
                          paddingLeft: "5px",
                        }
                      : {
                          fontSize: "medium",
                          color: "gray",
                          paddingLeft: "5px",
                        }
                  }
                >
                  {t("common.trackPart")}
                </div>
              </div>
              <div className="ItemCardStylePath">
                {measurement?.classification === ""
                  ? "BIS"
                  : measurement?.classification}
                <div
                  style={
                    width <= cPhoneWidthLim || height <= cPhoneWidthLim
                      ? {
                          fontSize: "10px",
                          color: "gray",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                        }
                      : {
                          fontSize: "medium",
                          color: "gray",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                        }
                  }
                >
                  {t("common.speedClass")}
                </div>
              </div>
              <div className="ItemCardStylePath">
                {distanceData("startKm")}
                <span
                  style={
                    width <= cPhoneWidthLim || height <= cPhoneWidthLim
                      ? {
                          fontSize: "10px",
                          color: "gray",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                        }
                      : {
                          fontSize: "medium",
                          color: "gray",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                        }
                  }
                >
                  km +
                </span>
                {distanceData("startMeter")}
                <span
                  style={
                    width <= cPhoneWidthLim || height <= cPhoneWidthLim
                      ? { fontSize: "10px", color: "gray", paddingLeft: "3px" }
                      : {
                          fontSize: "medium",
                          color: "gray",
                          paddingLeft: "3px",
                        }
                  }
                >
                  m {t("metaData.from")}
                </span>
              </div>

              <div className="ItemCardStylePath">
                {distanceData("endKm")}
                <span
                  style={
                    width <= cPhoneWidthLim || height <= cPhoneWidthLim
                      ? {
                          fontSize: "10px",
                          color: "gray",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                        }
                      : {
                          fontSize: "medium",
                          color: "gray",
                          paddingLeft: "3px",
                          paddingRight: "3px",
                        }
                  }
                >
                  km +
                </span>
                {distanceData("endMeter")}
                <span
                  style={
                    width <= cPhoneWidthLim || height <= cPhoneWidthLim
                      ? { fontSize: "10px", color: "gray", paddingLeft: "3px" }
                      : {
                          fontSize: "medium",
                          color: "gray",
                          paddingLeft: "3px",
                        }
                  }
                >
                  m {t("metaData.to")}
                </span>
              </div>
            </div>
          </div>
        </div>

        {animation}

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => {
            setExpandCard(!expandCard);
          }}
        >
          <span
            className={"k-icon k-i-arrow-chevron-down"}
            style={{
              height: "30px",
              fontSize: "24px",
              transform: expandCard ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};
