import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Step, StepHandle, Stepper } from "@progress/kendo-react-layout";
import {
  measurementProps,
  pipelineProps,
  processingState,
} from "../../../../store/scheduler/types";
import "./statusProgress.css";
import { useHistory } from "react-router-dom";
import { setSelectedMeasurement } from "../../../../store/scheduler/actions";
import rest from "../../../../rest";
import { useDispatch, useSelector } from "react-redux";
import { ShareLinkPopup } from "./externalLinkShare";
import { cPhoneWidthLim } from "../../../../app";
import {
  convertToHmiStatus,
  setLastVisitedPlanAndMeasurement,
} from "../../../../helpers/genericHelpers";
import { appState } from "../../../../store";
import { roleType } from "../../../../store/user/types";
import { useTranslation } from "react-i18next";
import { processingStatus } from "../../../../store/scheduler/types";

interface statusProgressProps {
  measurement?: measurementProps;
  pipelines?: pipelineProps[];
}

export enum measurementStatusHMI {
  start = 0,
  processing,
  ProcessingDone,
  trafikverket,
  trafikverketDone,
  plot,
  plotDone,
  excel,
  excelDone,
  email,
  emailDone,
}

export enum measurementStatusStep {
  inProgress = 0,
  dataIntegration,
  plot,
  excel,
  share,
}

interface step {
  label?: string;
  icon?: string;
  disabled?: boolean;
  optional?: boolean;
  isValid?: boolean;
  current?: boolean;
  style?: CSSProperties;
  onFocus?: StepHandle;
  className?: string;
  errornotification?: string;
}

interface steps {
  [processingState.dataProcessing]: step;
  [processingState.integration]: step;
  [processingState.writingToDynamodb]: step;
  [processingState.excel]: step;
  [processingState.email]: step;
}

export const TrafikverketErrorMsg = "trafikverket integration failed";
export const trafikverketErrorPipelineDetected = (
  pipelines?: pipelineProps[]
) => {
  if (pipelines !== undefined) {
    return pipelines?.some((pipeline) => {
      return (
        pipeline.state === processingState.integration &&
        pipeline.jobMessage !== null
      );
    });
  } else {
    return false;
  }
};

export const checkTrafikverketError = (errorMsg: string | undefined) => {
  if (errorMsg === TrafikverketErrorMsg) {
    return true;
  } else {
    return false;
  }
};

export const checkStateErrorMessage = (
  state: processingState,
  pipelines?: pipelineProps[]
) => {
  return (
    pipelines?.find(
      (pipeline) =>
        pipeline.state === state &&
        pipeline.state !== processingState.integration
    )?.jobMessage !== null
  );
};

export const StatusProgress: React.FC<statusProgressProps> = ({
  measurement,
  pipelines,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "statusProgress" });

  const initialSteps: steps = {
    [processingState.dataProcessing]: {
      label: t("onGoing").toString(),
      icon: "k-icon k-i-gears",
      disabled: true,
    },
    [processingState.integration]: {
      label: t("integration").toString(),
      icon: "k-icon k-i-connector",
      optional: true,
      disabled: true,
    },
    [processingState.writingToDynamodb]: {
      label: t("plot").toString(),
      icon: "k-icon k-i-graph",
      disabled: true,
    },
    [processingState.excel]: {
      label: t("excel").toString(),
      icon: "k-icon k-i-file-excel",
      disabled: true,
    },
    [processingState.email]: {
      label: t("share").toString(),
      icon: "k-icon k-i-share",
      disabled: true,
    },
  };

  const [steps, setSteps] = useState<steps>(initialSteps);
  const [currentStatus, setcurrentStatus] = useState(0);
  const [show, setShow] = useState(false);
  const [Hover, setHover] = useState(false);
  const [TooltipIcon, setTooltipIcon] = useState("");
  const clickCountRef = useRef(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state: appState) => {
    return state.user.currentUser;
  });

  const [width, setWidth] = useState(
    window.innerWidth > cPhoneWidthLim
      ? window.innerWidth / 2
      : window.innerWidth
  );

  const ref = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    if (ref.current) {
      setWidth(ref.current.clientWidth);
    }
  };
  useEffect(() => {
    if (ref.current) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (ref.current) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (ref.current) {
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (ref.current) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [ref.current]);

  // Processing state contains more states than we display in the pipeline this is a subset of these
  // ORDER IS IMPORTANT SINCE WE USE THEIR INDEXES
  const stepperProcessingState = [
    processingState.dataProcessing,
    processingState.integration,
    processingState.writingToDynamodb,
    processingState.excel,
    processingState.email,
  ];

  type StepperProcessingType =
    | processingState.dataProcessing
    | processingState.integration
    | processingState.writingToDynamodb
    | processingState.excel
    | processingState.email;

  const areAllChunksDone = (pipelines: pipelineProps[]) => {
    // Are all chunks done?
    const processingPipelines = pipelines.filter(
      (pipe) => pipe.state === processingState.dataProcessing
    );
    // Does the chunk include end?
    let chunkNumber;
    if (processingPipelines.some((pipe) => pipe.output?.includes("end"))) {
      // could be done, check if we have all chunks
      // Has text format "chunk X"
      const chunk = processingPipelines.find((pipe) =>
        pipe.output?.includes("end")
      )?.note;
      if (chunk !== undefined) {
        chunkNumber = parseInt(chunk.substring(6));

        if (processingPipelines.length - 1 === chunkNumber) {
          // Data processing is done
          return true;
        }
      }
    }
    // We are guaranteed not to be done.
    return false;
  };

  // Function that takes steps and updates them based on what's in a pipelineProps[].
  const updatePipelineStatus = (
    steps: steps,
    state: StepperProcessingType,
    newestProcessNumber?: number,
    pipelines?: pipelineProps[]
  ): steps => {
    const currentPipelines = pipelines?.filter(
      (pipe) => pipe.processNumber === newestProcessNumber
    );

    // This section handles all states EXCEPT for data processing which is handled above.
    const pipeline = currentPipelines?.find((pipe) => pipe.state === state);

    // Encountered the state in pipeline? If not, disable.
    if (currentPipelines?.some((pipe) => pipe.state === state)) {
      // Unique if we have data processing, we need to handle if we have multiple chunks with different statuses.
      // If all are done, then we allow to go into set status===done. If not all are done, return the steps again.
      if (state === processingState.dataProcessing) {
        // Check first if error.
        if (
          currentPipelines.some(
            (pipe) =>
              pipe.state === processingState.dataProcessing &&
              pipe.status === processingStatus.error
          )
        ) {
          steps = {
            ...steps,
            [state]: {
              ...steps?.[state],
              isValid: false,
              icon: "k-icon k-i-close",
              errornotification: "k-icon k-i-warning",
              disabled: true,
            },
          };
          return steps;
        }
        // No error, check if all dp chunks are done
        const chunksAreDone = areAllChunksDone(currentPipelines);

        if (!chunksAreDone) {
          return steps;
        }
      }

      const errorMsgFound =
        errorMessages &&
        errorMessages
          .filter((msg: string) => !msg.includes(TrafikverketErrorMsg))
          .some((msg: string) => msg !== "")
          ? true
          : false;

      // Initialize current step as disabled. Overwrite if error or done.
      switch (pipeline?.status) {
        case processingStatus.error:
          return (steps = {
            ...steps,
            [state]: {
              ...steps?.[state],
              isValid: false,
              icon:
                state === processingState.integration
                  ? "k-icon k-font-icon k-i-cancel"
                  : "k-icon k-font-icon k-i-close",
              errornotification: "k-icon  k-font-icon k-i-warning",
              disabled: state !== processingState.integration,
            },
          });

        case processingStatus.done:
          return (steps = {
            ...steps,
            [state]: {
              ...steps?.[state],
              disabled: false,
              icon: initialSteps[state].icon,
              errornotification: undefined,
              isValid: true,
            },
          });

        default:
          if (errorMsgFound) {
            return (steps = {
              ...steps,
              [state]: {
                ...steps?.[state],
                isValid: false,
                icon:
                  state === processingState.integration
                    ? "k-icon k-font-icon k-i-cancel"
                    : "k-icon k-font-icon k-i-close",
                errornotification: "k-icon  k-font-icon k-i-warning",
                disabled: true,
              },
            });
          } else {
            return (steps = {
              ...steps,
              [state]: {
                ...steps?.[state],
                disabled: true,
                icon: initialSteps[state].icon,
                errornotification: undefined,
                isValid: true,
              },
            });
          }
      }
    } else {
      return (steps = {
        ...steps,
        [state]: {
          ...steps?.[state],
          disabled: true,
          icon: initialSteps[state].icon,
          errornotification: undefined,
          isValid: true,
        },
      });
    }
  };

  useEffect(() => {
    let currentSteps = steps;
    let currentStepIdx = 0; // Initialize as dp step

    const newestProcessNumber =
      pipelines && pipelines.length > 0
        ? pipelines?.reduce((prevPipe, currPipe) =>
            prevPipe.processNumber > currPipe.processNumber
              ? prevPipe
              : currPipe
          ).processNumber
        : 0;

    for (const idx in stepperProcessingState) {
      const state = stepperProcessingState[idx];

      currentSteps = updatePipelineStatus(
        currentSteps,
        state as StepperProcessingType,
        newestProcessNumber,
        pipelines
      );
      if (
        pipelines?.some(
          (pipe) =>
            pipe.state === state && pipe.processNumber === newestProcessNumber
        )
      ) {
        currentStepIdx = stepperProcessingState.indexOf(state);
      }
    }

    // Update stepper
    setcurrentStatus(currentStepIdx);
    setSteps({ ...currentSteps });
  }, [pipelines, measurement]);

  const handleExcelClick = () => {
    rest
      .get("/files", {
        params: { filter: { ownerID: measurement?.id } },
        withCredentials: true,
      })
      .then((result) => {
        const files = result.data.list;

        const foundFile = files.find(
          (file: any) =>
            file.type === "excel" && file.ownerID === measurement?.id
        );

        rest
          .get("/files/" + foundFile.id + "/downloadURL", {
            withCredentials: true,
          })
          .then(async (result) => {
            const link = document.createElement("a");
            // link.href = result.data.presignedURL; // The URL
            // link.setAttribute("download", foundFile.name); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            const response = await fetch(result.data.presignedURL);
            const data = await response.blob();
            const url = window.URL.createObjectURL(new Blob([data]));

            link.href = url;
            link.setAttribute("download", foundFile.name);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            document.body.removeChild(link);
          })
          .catch((err) => {
            alert(t("unableToFindExcel"));
            console.log(err);
          });
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          alert(t("controlConnection"));
        } else if (error.message.includes("404")) {
          alert(t("unableToFindExcel"));
        } else {
          alert(t("unexpectedError"));
        }
      });
  };

  const statusClickEvent = (icon: string) => {
    if (icon === steps?.[processingState.writingToDynamodb].icon) {
      if (measurement) {
        dispatch(setSelectedMeasurement(measurement?.id));

        history.push("/dashboard");

        setLastVisitedPlanAndMeasurement(measurement.planID, measurement?.id);
      }
    } else if (icon === steps.excel.icon) {
      handleExcelClick();
    } else if (icon === "k-icon k-i-share") {
      setShow(!show);
    }
  };

  const toolTipStatusContent = (step: processingState) => {
    if (step === processingState.writingToDynamodb && Hover) {
      return TooltipIcon === steps?.[processingState.writingToDynamodb].icon ? (
        <>
          <div className="tool-tip">{t("goToDashboard")}</div>
          <div className="tool-tip2"></div>
        </>
      ) : null;
    } else if (step === processingState.excel && Hover) {
      return TooltipIcon === steps?.[processingState.excel].icon ? (
        <>
          <div className="tool-tip">{t("downloadExcel")}</div>
          <div className="tool-tip2"></div>
        </>
      ) : null;
    } else if (step === processingState.email && Hover) {
      return TooltipIcon === steps?.[processingState.email].icon ? (
        <>
          <div className="tool-tip">{t("shareMeasurement")}</div>
          <div className="tool-tip2"></div>
        </>
      ) : null;
    } else if (step === processingState.integration && Hover) {
      if (TooltipIcon === "k-icon k-i-cancel") {
        return TooltipIcon === steps?.[processingState.integration].icon ? (
          <>
            <div className="tool-tip">
              {t("lastkajenIntegration")} <br /> {t("unavailable")}
            </div>
            <div className="tool-tip2"></div>
          </>
        ) : null;
      } else {
        return TooltipIcon === steps?.[processingState.integration].icon ? (
          <>
            <div className="tool-tip">
              {t("lastkajenIntegration")} <br /> {t("available")}
            </div>
            <div className="tool-tip2"></div>
          </>
        ) : null;
      }
    } else if (
      step === processingState.dataProcessing &&
      Hover &&
      steps?.[processingState.dataProcessing].errornotification?.includes(
        "upload"
      )
    ) {
      return TooltipIcon === steps?.[processingState.dataProcessing].icon ? (
        <>
          <div className="tool-tip">
            {t("uploadError")} <br /> {t("awaitIPC")}
          </div>
          <div className="tool-tip2"></div>
        </>
      ) : null;
    } else if (step === processingState.dataProcessing && Hover) {
      return TooltipIcon === steps?.[processingState.dataProcessing].icon ? (
        <>
          <div className="tool-tip">{t("dataIsProcessing")}</div>
          <div className="tool-tip2"></div>
        </>
      ) : null;
    } else {
      return null;
    }
  };

  const renderStatusLabel = (props: any) => {
    if (width < 510) {
      return (
        <span
          className="k-step-label"
          style={{
            fontSize: "x-small",
            color:
              props.isValid === false
                ? props.icon === "k-icon k-font-icon k-i-cancel" ||
                  props.icon === "k-icon k-font-icon k-i-warning"
                  ? "orange"
                  : "red"
                : "",
          }}
        >
          <span className="k-step-text">
            {props.label}
            <br />
            {props.errornotification ? (
              <>
                {props.label === "Integration" ? (
                  <>
                    {"(" + t("unavailable") + ")"} <br />
                  </>
                ) : null}
                <span
                  className={props.errornotification}
                  style={{ fontSize: "10px", paddingRight: "10px" }}
                />
                <br />
              </>
            ) : props.label === "Integration" && !props.disabled ? (
              "(" + t("available") + ")"
            ) : null}
          </span>
        </span>
      );
    } else {
      return (
        <span
          className="k-step-label"
          style={{
            fontSize: "small",
            color:
              props.isValid === false
                ? props.icon === "k-icon k-font-icon k-i-cancel" ||
                  props.icon === "k-icon k-font-icon k-i-warning"
                  ? "orange"
                  : "red"
                : "",
          }}
        >
          <span className="k-step-text">
            {props.label}
            <br />
            {props.errornotification ? (
              <>
                {props.label === "Integration" ? (
                  <>
                    {"(" + t("unavailable") + ")"} <br />
                  </>
                ) : null}

                <span
                  className={props.errornotification}
                  style={{ fontSize: "10px", paddingRight: "10px" }}
                />
                <br />
              </>
            ) : props.label === "Integration" && !props.disabled ? (
              "(" + t("available") + ")"
            ) : null}
          </span>
        </span>
      );
    }
  };

  const CustomStep = (props: any) => {
    return (
      <Step {...props}>
        {toolTipStatusContent(stepperProcessingState[props.index])}
        <div
          className="k-step-indicator"
          // onDoubleClick={(e) => {
          //   e.stopPropagation();
          //   if (!props.disabled) statusClickEvent(props.icon);
          // }}
          // onTouchEnd={(e) => {
          //   e.stopPropagation();
          //   if (!props.disabled && /SamsungBrowser/i.test(navigator.userAgent))
          //     statusClickEvent(props.icon);
          // }}
          onClick={(e) => {
            clickCountRef.current += 1;
            setTimeout(() => {
              if (clickCountRef.current === 1) {
                console.log("Single click event");
                setHover(!Hover);
                e.stopPropagation();
                if (!props.disabled) setTooltipIcon(props.icon);
              }
              clickCountRef.current = 0;
            }, 250);

            if (clickCountRef.current === 2) {
              e.stopPropagation();
              if (!props.disabled) statusClickEvent(props.icon);
              clickCountRef.current = 0;
            }
          }}
        >
          <span className={props.icon} />
        </div>
        {renderStatusLabel(props)}
      </Step>
    );
  };

  // Create an array that contains every error in text format.
  let errorMessages: any;
  if (measurement && measurement?.pipelines !== null) {
    // Only check errors if Pipeline exists
    const maxProcessNumber =
      measurement?.pipelines !== undefined && measurement?.pipelines.length > 0
        ? measurement?.pipelines?.reduce((prev, curr) =>
            prev.processNumber > curr.processNumber ? prev : curr
          ).processNumber
        : 0;

    errorMessages = measurement?.pipelines?.map((pipeline) =>
      pipeline.status === processingStatus.error &&
      pipeline.processNumber === maxProcessNumber
        ? // && pipeline.measurementID === measurement.id
          `${pipeline.state} ${pipeline.status} ${pipeline.jobMessage}`
        : ""
    );
  }

  // If we have errors that are not from microservices, append them here
  if (measurement && measurement.errorMessage && errorMessages)
    errorMessages.push(measurement.errorMessage);

  return (
    <div className="stepperWrapper" ref={ref}>
      <ShareLinkPopup
        measurement={measurement as measurementProps}
        show={show}
        setShow={setShow}
      />
      <Stepper
        style={{ width: "90%", paddingBottom: "6px" }}
        value={currentStatus}
        items={[
          steps?.[processingState.dataProcessing],
          steps?.[processingState.integration],
          steps?.[processingState.writingToDynamodb],
          steps?.[processingState.excel],
          steps?.[processingState.email],
        ]}
        className="k-stepper"
        item={CustomStep}
      />
      <div className="error-message-container">
        {errorMessages &&
        currentUser &&
        currentUser.roles[0].name === roleType.admin ? (
          <div className="error-message">
            <div style={{ paddingRight: "5px", paddingLeft: "5px" }}>
              {t("errorMessage")}
            </div>{" "}
            <div style={{ color: "red" }}>
              {errorMessages
                ? errorMessages.map((msg: any) => {
                    return msg + "\n";
                  })
                : ""}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
