export enum machineActionType {
  setAllMachines = "SET_ALL_MACHINES",
  setSelectedMachine = "SET_SELECTED_MACHINE",
  setMachineHasChanged = "SET_MACHINE_HAS_CHANGED",
  deleteMachine = "REMOVE_MACHINE",
  addNewReduxMachine = "ADD_NEW_REDUX_MACHINE",
  updateMachineProperty = "UPDATE_MACHINE_PROPERTY",
  updateMachineProps = "UPDATE_MACHINE_PROPS",
}

export interface addNewReduxMachine {
  type: typeof machineActionType.addNewReduxMachine;
  payload: machineType;
}

export interface setAllMachines {
  type: typeof machineActionType.setAllMachines;
  payload: machineType[];
}

export interface deleteMachine {
  type: typeof machineActionType.deleteMachine;
  payload: string;
}

export interface setSelectedMachine {
  type: typeof machineActionType.setSelectedMachine;
  payload: machineType["id"];
}

export interface setMachineHasChanged {
  type: typeof machineActionType.setMachineHasChanged;
  payload: boolean;
}

export interface updateMachineProperty<T extends keyof machineType> {
  type: typeof machineActionType.updateMachineProperty;
  payload: Pick<machineType, T>;
}

export interface updateMachineProps {
  type: typeof machineActionType.updateMachineProps;
  payload: machineType;
}

export type machineAction =
  | addNewReduxMachine
  | updateMachineProperty<keyof machineType>
  | setAllMachines
  | deleteMachine
  | setSelectedMachine
  | setMachineHasChanged
  | updateMachineProps;

export interface machineState {
  currentMachine?: machineType;
  allMachines: machineType[];
  selectedMachine: machineType["id"];
  machineHasChanged: boolean;
}

export interface machineType {
  id: string;
  name: string;
  description?: string;
  hashKey?: string;
  status?: string;
  coordinates?: {
    longitude: number;
    latitude: number;
    altitude: number;
  };
  companyID: string;
  sentToServer?: boolean;
  clientID?: string;
  secretKey?: string;
  nameTag?: string;
  kmCounter?: number | undefined;
}
